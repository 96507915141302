<template>
  <v-container class="pa-0" fluid>
    <kurcc-inspection-list :key="listComponentKey"
                           :filter="[
                             'status=upcoming',
                             `inspector_id=${$auth.user().id}`,
                             'is_today=0', `types=[${types}]`
                           ]"
                           :selected-ids.sync="selectedIds"
                           :show-select-button="adding"
                           @change="reloadInspectionList"/>
    <kurcc-f-a-b :center="adding" :loading="loading" :text="text" icon="mdi-text-box-plus-outline"
                 @click="addForToday"/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccUpcomingPage',
  components: {
    KurccInspectionList: () => import('@/modules/app/components/KurccInspectionList'),
    KurccFAB: () => import('@/modules/app/components/KurccFAB')
  },
  data () {
    return {
      adding: false,
      loading: false,
      selectedIds: [],
      listComponentKey: 0
    }
  },
  computed: {
    text () {
      return this.adding ? this.$vuetify.lang.t('$vuetify.pages.upcoming.chooseForToday') : null
    },
    anyItemsSelected () {
      return this.selectedIds.length > 0
    },
    enteringAddMode () {
      return this.adding === false
    },
    exitingAddMode () {
      return this.adding === true
    },
    types () {
      return `"${this.$route.query.tab}"`
    }
  },
  watch: {
    $route: function () {
      this.reloadInspectionList()
    }
  },
  methods: {
    reloadInspectionList () {
      this.listComponentKey++
    },
    addForToday () {
      if (this.enteringAddMode) {
        this.adding = true
        return
      }

      if (this.exitingAddMode) {
        if (this.anyItemsSelected) {
          this.loading = true
          this.$store.dispatch('addForToday', {
            inspector_id: this.$auth.user().id,
            inspection_ids: this.selectedIds
          }).then(() => {
            this.selectedIds = []
            this.adding = false
            this.reloadInspectionList()
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.adding = false
        }
      }
    }
  }
}
</script>
